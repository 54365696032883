import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import * as React from "react";

export default function CustomModal({
  show,
  onClose,
  header,
  body,
  footer,
  size,
}: {
  show: boolean;
  header: string | React.ReactNode;
  body: React.ReactNode;
  onClose?: () => void;
  footer?: React.ReactNode;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={show}
      fullWidth // enables the modal to use the full width defined by maxWidth
      maxWidth={size ?? "xl"} // sets the maximum width to 'lg' (large). Options: 'xs', 'sm', 'md', 'lg', 'xl', false
      sx={{
        "& .MuiPaper-root": {
          backgroundImage: ` none !important`,
          backgroundColor: "#111936 !important",
        },
      }}
    >
      <DialogTitle
        sx={{ m: 0, p: 2, color: "white" }}
        id="customized-dialog-title"
      >
        <Box sx={{ textAlign: "center", fontSize: "1.4rem" }}>{header}</Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 10,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon sx={{ width: "30px", height: "30px" }} />
        </IconButton>
      </DialogTitle>
      <Box sx={{ backgroundColor: "#202940", overflowY: "auto" }}>
        <DialogContent dividers>{body}</DialogContent>
        <DialogActions sx={{ p: 0 }}>{footer}</DialogActions>
      </Box>
    </Dialog>
  );
}
